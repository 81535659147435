import "@/styles/globals.css";
import "@fontsource-variable/inconsolata";
import "@fontsource-variable/inter";

import Layout from "@/components/Layout";
import { queryClient } from "@/lib/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import type { AppProps } from "next/app";
import PlausibleProvider from "next-plausible";
import { PLAUSIBLE_DOMAIN } from "@/constants";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <PlausibleProvider domain={PLAUSIBLE_DOMAIN}>
          <Layout id="main" className="font-sans">
            <Component {...pageProps} />
          </Layout>
        </PlausibleProvider>
      </QueryClientProvider>
    </>
  );
}
