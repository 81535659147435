import { ComponentProps } from "react";

export const SkipIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400.5" {...props}>
      <title className="sr-only">skip.build</title>
      <g id="prefix__Layer_1">
        <path
          fill="currentColor"
          d="M264.5 20.6c81 0 149.7 54 172 127.8 8.7 5.4 17.2 11 25.2 16.8C445.1 71.5 363 0 264.5 0 171.8 0 93.6 63.4 70.9 149.1c5.5 4.7 11.5 9.4 17.9 14.1 17.1-81.3 89.4-142.6 175.7-142.6zM440.2 237.3c-17.1 81.3-89.4 142.5-175.7 142.5-81 0-149.7-54-172-127.8-8.7-5.4-17.2-11-25.2-16.8C83.9 329 166 400.5 264.5 400.5c92.7 0 170.9-63.4 193.6-149.1-5.5-4.7-11.5-9.4-17.9-14.1z"
        />
        <path
          fill="currentColor"
          d="M457.8 340.3c35.1 0 59.1-12.3 67.7-34.7 11.7-30.4-8.4-62.4-27.4-83.9-24.2-27.4-61.9-54.9-103.4-75.3-70-34.4-137.1-43.9-171-24.2-4 2.3-7 6.3-8.3 11.1-1.4 4.8-1 10 1.1 14.5s5.5 7.9 9.8 9.4c4.3 1.6 8.8 1.1 12.8-1.2 20.7-12 76.2-7.2 142.2 25.2 5.1 2.5 10.2 5.2 15.2 7.9 32.4 16.6 61.4 36.6 81.7 56.2 33.7 32.6 34.5 55.7 29.3 69.4-6 15.6-26.4 23.5-60.6 23.5-6.5 0-13.5-.3-20.9-.9 11.2 2 21.8 3 31.8 3zM71.2 60.2c-35.1 0-59.1 12.3-67.7 34.7-11.7 30.4 8.4 62.4 27.4 83.9 24.2 27.4 61.9 54.9 103.4 75.3 70 34.4 137.1 43.9 171 24.2 4-2.3 7-6.3 8.3-11.1 1.4-4.8 1-10-1.1-14.5s-5.5-7.9-9.8-9.4c-4.3-1.6-8.8-1.1-12.8 1.2-20.7 12-76.2 7.2-142.2-25.2-5.1-2.5-10.2-5.2-15.2-7.9-32.4-16.6-61.4-36.6-81.7-56.2-33.7-32.6-34.5-55.7-29.3-69.4 6-15.6 26.4-23.5 60.6-23.5 6.5 0 13.5.3 20.9.9-11.2-2.1-21.8-3-31.8-3z"
        />
        <g>
          <path
            fill="currentColor"
            d="M683.7 242.5c4.8 0 9.5-1.7 14-5.1 4.5-3.4 7.4-7.5 8.9-12.3 1.5-4.8 1-9-1.3-12.4-2.4-3.4-6-5.1-10.8-5.1h-19.7c-8.8 0-15.3-3.1-19.6-9.4-4.4-6.2-5.2-13.7-2.5-22.6 2.7-8.8 8.1-16.3 16.3-22.5 8.1-6.3 16.6-9.4 25.4-9.4h19.7c8.8 0 15.4 3.1 19.7 9.4 4.4 6.2 5.2 13.7 2.5 22.5-.6 2-1.8 3.7-3.7 5.1-1.9 1.4-3.8 2.1-5.8 2.1s-3.5-.7-4.4-2.1c-1-1.4-1.2-3.1-.6-5.1 1.5-4.8 1-8.9-1.4-12.3s-6-5.1-10.8-5.1h-19.7c-4.8 0-9.4 1.7-13.9 5.1-4.5 3.4-7.4 7.5-8.9 12.3-1.5 4.8-1 9 1.3 12.4 2.4 3.4 6 5.1 10.8 5.1h19.7c8.8 0 15.4 3.1 19.7 9.4 4.4 6.2 5.2 13.7 2.5 22.6-2.7 8.8-8.1 16.3-16.3 22.5-8.2 6.3-16.7 9.4-25.5 9.4h-19.7c-8.8 0-15.3-3.1-19.6-9.4-4.4-6.2-5.2-13.7-2.5-22.5.6-2 1.8-3.7 3.7-5.1 1.8-1.4 3.7-2.1 5.7-2.1s3.5.7 4.5 2.1c1 1.4 1.2 3.1.6 5.1-1.5 4.8-1 8.9 1.4 12.3 2.4 3.4 6 5.1 10.8 5.1h19.5zM772.4 150.8c.6-1.9 1.8-3.6 3.7-5.1 1.8-1.4 3.7-2.1 5.7-2.1s3.5.7 4.5 2.1c1 1.4 1.2 3.1.6 5.1l-13.3 43.5 68.8-48.8c1.9-1.4 3.8-2 5.8-1.9 1.9.1 3.3.9 4.3 2.4.9 1.5.9 3.2.2 5.2s-2 3.7-3.9 5l-56.6 40.2 30 48.5c.9 1.5 1 3.2.3 5.1-.7 2-1.9 3.7-3.8 5.1-1.8 1.3-3.6 1.9-5.5 1.9-2.1 0-3.6-.8-4.6-2.3l-30-48.7-10.9 7.7-11 36c-.6 2-1.8 3.7-3.7 5.1-1.9 1.4-3.8 2.1-5.8 2.1s-3.5-.7-4.4-2.1c-1-1.4-1.2-3.1-.6-5.1l30.2-98.9zM856.4 256.9c-2 0-3.5-.7-4.4-2.1-1-1.4-1.2-3.1-.6-5.1l30.3-99c.6-1.9 1.8-3.6 3.7-5.1 1.8-1.4 3.7-2.1 5.7-2.1s3.5.7 4.5 2.1c1 1.4 1.2 3.1.6 5.1l-30.3 99c-.6 2-1.8 3.7-3.7 5.1-1.9 1.4-3.8 2.1-5.8 2.1zM929.8 158c-2 0-3.5-.7-4.4-2.1-1-1.4-1.2-3.1-.6-5.1.6-1.9 1.8-3.6 3.7-5.1 1.8-1.4 3.7-2.1 5.7-2.1h38.3c10.2 0 17.8 3.6 22.8 10.8 5 7.3 6 16 2.9 26.1-3.1 10.2-9.4 18.9-18.9 26.1-9.4 7.2-19.2 10.8-29.4 10.8h-31l-9.9 32.2c-.6 2-1.8 3.7-3.7 5.1-1.9 1.4-3.8 2.1-5.8 2.1s-3.5-.7-4.4-2.1c-1-1.4-1.2-3.1-.6-5.1l12.1-39.4c.6-2 1.8-3.7 3.7-5.1 1.8-1.4 3.7-2.1 5.7-2.1h38.3c6.2 0 12.2-2.2 17.9-6.6 5.8-4.4 9.6-9.7 11.5-16 1.9-6.2 1.3-11.5-1.8-16-3-4.4-7.7-6.6-13.9-6.6h-38.2z"
          />
        </g>
      </g>
    </svg>
  );
};
