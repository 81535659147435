import Link from "next/link";
import { DydxIcon } from "./icons/Dydx";
import { ObservatoryIcon } from "./icons/Observatory";
import { SkipIcon } from "./icons/Skip";

const Navbar = () => {
  return (
    <nav className="p-4">
      <div className="max-w-screen-lg mx-auto">
        <Link
          href="/"
          className="flex items-center flex-wrap gap-4 justify-center sm:justify-between"
        >
          <div className="flex flex-nowrap items-center gap-4">
            <DydxIcon className="h-5 sm:h-8" />
            <span className="text-2xl sm:text-4xl leading-[32px] font-black">
              /
            </span>
            <ObservatoryIcon className="h-5 sm:h-8" />
          </div>
          <div className="flex items-center gap-4 justify-center">
            <p className="font-mono text-sm sm:text-md">Developed by</p>
            <SkipIcon className="h-8" />
          </div>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
